<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.el-message-box {
  width: 96% !important;
  max-width: 450px;
}
.el-message {
  min-width: 340px !important;
}
.el-dialog__title {
  font-size: 24px !important;
  font-weight: 600;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em;
  color: #4e4e4e;
}
</style>