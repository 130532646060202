import router from '@/router'
const write = ['/login', '/404', '/']
router.beforeEach(async (to, from, next) => {
  document.title = '乌衣巷AI美甲'
  next()
})

// 后置守卫

router.afterEach(() => { })
